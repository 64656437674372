import { useContext } from "react";

import {
  userIsAdministrator,
  userIsModerator,
} from "../components/util/CognitoUserUtils.js";
import { API } from "aws-amplify";

export function ImportSlugPeople(cognitoUser, personsToImport) {
  const isAdmin = userIsAdministrator(cognitoUser);
  if (!isAdmin) {
    return {
      statusCode: 403,
      body: "You need to be an administrator to run an import",
    };
  }
  bulkImport(personsToImport);
  return { statusCode: 200, body: `Import ran for ${personsToImport.length}` };
}

export function UpdateSlugPerson(cognitoUser, person) {
  const isModerator = userIsModerator(cognitoUser);
  if (!isModerator) {
    return {
      statusCode: 403,
      body: "You need to be a moderator to update Slug People",
    };
  }
  console.log(`CALLING THE API...${person}`);
  singleUpdate(person);
  return { statusCode: 200, body: `Updated Person ${JSON.stringify(person)}` };
}

const singleUpdate = async (person) => {
  const myAPI = "addslugpersonapi";
  const path = "/updateperson";

  const updatePerson = () => {
    (async function () {
      const res = await API.put(myAPI, path, {
        queryStringParameters: {
          person: JSON.stringify(person),
        },
      });
      console.log(
        `Added: ${JSON.stringify(person)} with result: ${res}`
      );
      
    })();
  };
  updatePerson();
};

const bulkImport = async (personsToImport) => {
  const myAPI = "addslugpersonapi";
  const path = "/addperson";
  console.log(`Importing: ${personsToImport.length}`);
  const importPeople = () => {
    (async function () {
      var count = 1;
      for (const person of personsToImport) {
        var personArray = [];
        personArray.push(person);
        const res = await API.put(myAPI, path, {
          queryStringParameters: {
            person: JSON.stringify(personArray),
          },
        });
        console.log(
          `${count} Added: ${JSON.stringify(person)} with result: ${res}`
        );
        count = count + 1;
        console.log(new Date());
      }
    })();
  };
  importPeople();
};
