import React from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { useContext } from "react";
import { View } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";

import { FilteredPeopleContext } from "../contexts/filteredpeoplecontext";

import { subscriptionIsCurrent } from "../components/util/UserStatusUtil";
import PersonCard from "./PersonCard";
import { CognitoUserContext } from "../contexts/cognitousercontext";

export default function SlugSearchResults() {
  const { filteredPeople } = useContext(FilteredPeopleContext);

  const { cognitoUser } = useContext(CognitoUserContext);
  //console.log("EMAP in SSR DEFAULT  is ", enumMap);

  //console.log("COGNITOUSER IN SSR", cognitoUser);
  // uncomment this analysePerson ();

  try {
    // here we call something that gets the result from Rekognition
  } catch (error) {
    console.log("BERR", error);
  }

  const isCurrent =
  subscriptionIsCurrent(cognitoUser);
  const blur = isCurrent ? 0 : 8;
  const blurFilter = `blur(${blur}px)`;

  console.log (`FILTERED PEOPLE COUNT: ${filteredPeople.length}`);
  if (filteredPeople.length === 0) {
    return <div />;
  } else {
    return (
      <View className="App">
        <Grid
          container
          spacing={1}
          sx={{ filter: blurFilter }}
          alignItems="center"
          justifyContent="center"
        >
          {filteredPeople.map((person) => (
            <Grid key={person.external_id} item xs="auto">
              <PersonCard
                key={person.external_id}
                person={person}
                active={isCurrent}
              />
            </Grid>
          ))}
        </Grid>
      </View>
    );
  }
}
