import { API } from "aws-amplify";
export const a = "UserAttributes.at('sub').Value";
export const STRIPE_SUB_EXPIRY = "custom:stripeSubExpiry";
export const STRIPE_SUB_STATUS = "custom:slugSubscrStatus";
export const STRIPE_SUBSCR_ID = "custom:subscriptionId";
export const STRIPE_SUBSCR_ID_SEARCHABLE = "preferred_username";
export const COGNITO_SUB = "sub";
export const COGNITO_EMAIL = "email";

export async function getCognitoUser(cognitoSub) {
  const myAPI = "sluguserapi";
  const path = "/user";

  const result = await API.get(myAPI, path, {
    queryStringParameters: {
      username: cognitoSub, // OPTIONAL
    },
  });
  const code = result.user.statusCode;
  const user = result.user.user;
  console.log("user is:", user);
  return { code, user };
}

export function getUserAttributeAt(cognitoUser, key) {
  return cognitoUser.UserAttributes.at(key).Value;
}

export function userIsAdministrator(cognitoUser) {
  let b = false;
  try {
    b = userIsInGroup(cognitoUser,"slughorn-administrator");
  } catch (error) {
    b = false;
  }
  return b;
}

export function userIsModerator(cognitoUser) {
  let b = false;
  try {
    b = userIsInGroup(cognitoUser,"slughorn-moderator");
  } catch (error) {
    b = false;
  }
  return b;
}

function userIsInGroup(cognitoUser, groupName) {
  let isMember = false;
  try {
    const groups =
      cognitoUser.signInUserSession.accessToken.payload["cognito:groups"];
    for (const group of groups) {
      if (groupName === group) {
        isMember = true;
        break;
      }
    }
  } catch (error) {
    isMember = false;
  }
  return isMember;
}

export function cognitoUserAttributesToMap(cognitoUser) {
  const mymap = cognitoUser.UserAttributes.reduce(function (map, obj) {
    map[obj.Name] = obj.Value;
    return map;
  }, {});
  return mymap;
}

export function updateUserAttributeViaIndex(cognitoUser, key, value) {
  //Find index of specific object using findIndex method.
  const i = cognitoUser.UserAttributes.findIndex((ob) => ob.Name === key);
  cognitoUser.UserAttributes[i] = value;
  return cognitoUser;
}
