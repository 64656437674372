
 
import {getCognitoUser,cognitoUserAttributesToMap,STRIPE_SUB_STATUS} from "./CognitoUserUtils.js";

export const STRIPE_ID_KEY = "custom:stripeId";
export const STRIPE_SUB_EXPIRY_KEY = "custom:stripeSubExpiry";
export const SLUG_SUBSCR_STATUS_KEY = "custom:slugSubscrStatus";

export const SLUG_SUBSCR_ACTIVE = "ACTIVE";
export const SLUG_SUBSCR_INACTIVE = "INACTIVE";
export const SLUG_SUBSCR_CANCELLED = "CANCELLED";

export const COGNITO_EVENT_SIGNIN = "signIn";
export const COGNITO_EVENT_AUTOSIGNIN = "autoSignIn";


export function CognitoUserSignedIn(cognitoUser){
    try{
        if (cognitoUser["username"]!== undefined){
            return true;
        }else{
            return false;
        }
    }catch(error){
        return false;
    }
};
/*
export function UserSignedIn(){
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    console.log ('Auth Status in USU is ', authStatus);
    try {
      return authStatus === "authenticated";
    } catch (error) {
  //    console.log(error);
      return false;
    }
  };
  */
  
  /**
   * This method determines if the current user is NOT eligible to subscribe
   * Sounds like odd logic but we use this to disable the subscription menu item
   * Criteria:
   *  User is not logged in OR
   *  User is logged in and subscription is active
   */
  export const NotEligibleToSubscribe = (cognitoUser) => {
    const sisa = UserSignedInAndSubscriptionActive(cognitoUser);
    const nsi = !CognitoUserSignedIn(cognitoUser);
    return sisa || nsi;
  };
  
  export function UserSignedInAndSubscriptionActive(cognitoUser){ 
    try {
      const active = userSubscriptionStatusACTIVE(cognitoUser.attributes[SLUG_SUBSCR_STATUS_KEY]);
      const inActive = userSubscriptionStatusINACTIVE(cognitoUser.attributes[SLUG_SUBSCR_STATUS_KEY]);
      const usi = CognitoUserSignedIn(cognitoUser);
      console.log(`sa is  ${active || inActive} and usi is ${usi}`);
      return usi && (active || inActive);
    } catch (error) {
      //console.log(error);
      return false;
    }
  };
  
  export function UserSignedInAndSubscriptionNotActive(cognitoUser){
    try {
      const sa = userSubscriptionStatusACTIVE(cognitoUser);
      const usi = CognitoUserSignedIn(cognitoUser);
      console.log(`sa is  ${sa} and usi is ${usi}`);
      return usi && !sa;
    } catch (error) {
      //console.log(error);
      return false;
    }
  };

  export function UserSignedInAndSubscriptionInactive(cognitoUser){
    try {   
      const sa = IsUserSubscriptionActive(cognitoUser);
      const usi = CognitoUserSignedIn(cognitoUser);
      console.log(`sa is  ${sa} and usi is ${usi}`);
      return usi && !sa;
    } catch (error) {
  //    console.log(error);
      return false;
    }
  };
  
  export function UserIsStripeRegistered(attributes){
    try {
        if ( attributes === undefined){
            return false;
          }
          const isRegistered = attributes.hasOwnProperty(STRIPE_ID_KEY);
          return isRegistered;
    } catch (error) {
    //  console.log(error);
      return false;
      
    }
  };
  
  export function GetStripeAccount(attributes) {
    try {     
      const stripeId = attributes.get(STRIPE_ID_KEY);
      return stripeId;
    } catch (error) {
      return null;
    }
  };
  
  
  export function IsUserSubscriptionActive (cognitoUser){
    if ( cognitoUser === undefined){
      return false;
    }
      const expiryDate = cognitoUser.attributes[STRIPE_SUB_EXPIRY_KEY];
      const slugSubscrStatus = cognitoUser.attributes[SLUG_SUBSCR_STATUS_KEY];
      const now = new Date().getTime()/1000;
    const isActive = expiryDate > now && 
    (slugSubscrStatus === SLUG_SUBSCR_ACTIVE ||slugSubscrStatus === SLUG_SUBSCR_INACTIVE );
    console.log ("Expiry Date Active ", isActive);
    return isActive;
  };

  export function userSubscriptionStatusACTIVE(status){
    try{
      /*
      .. this gets called from UserSignedInAndSubscriptionNotActive with a whole cognitouser object so it fails
    its all a bit messy and needs a thorough look at in this util class
    change it to just look at the subscription status an not the logged-in status too - perhaps?

      */
     return status === SLUG_SUBSCR_ACTIVE;
    } catch (error) {
      //console.log(error);
      return false;
    }
  }

  
  export function userSubscriptionStatusINACTIVE(status){
    try{
      return status === SLUG_SUBSCR_INACTIVE;
    } catch (error) {
      //console.log(error);
      return false;
    }
  };

  export function userSubscriptionStatusCANCELLED(status){
    try{
      return status === SLUG_SUBSCR_CANCELLED;
    } catch (error) {
      //console.log(error);
      return false;
    }
  };

  export function userSubscriptionExpiryDateInFuture(expiryDate){
    try{
      const now = new Date().getTime()/1000;
      const isActive = expiryDate > now;
      return isActive;
    }catch(error){
      return false;
    }
  };

  export function subscriptionIsCurrent(cognitoUser){
    try{
      const f = userSubscriptionExpiryDateInFuture(cognitoUser.attributes[STRIPE_SUB_EXPIRY_KEY]);
      return f;
    }catch(error){
      return false;
    }
  }


  export function subscriptionHasExpired(expiryDate){
    try{
      const now = new Date().getTime()/1000;
      console.log ("now ", now);
      const expiryDateAsInt = parseInt (expiryDate);
      const expired = expiryDateAsInt <= now;
      return expired;
    }catch(error){
      return false;
    }

  }

  export function userSubscriptionRenewalPaused(cognitoUser){
    try{
      const attMap = cognitoUserAttributesToMap(cognitoUser);
      console.log (attMap[STRIPE_SUB_EXPIRY_KEY]);
      const f = userSubscriptionExpiryDateInFuture(attMap[STRIPE_SUB_EXPIRY_KEY]);
      const inActive = userSubscriptionStatusINACTIVE(attMap[SLUG_SUBSCR_STATUS_KEY]);
      const paused = f && inActive;
      return paused;
    }catch(error){
      return false;
    }
  }

  export function userSubscriptionInactiveAndExpired(cognitoUser){
    try{
      const attMap = cognitoUser.attributes;
      console.log (attMap[STRIPE_SUB_EXPIRY_KEY]);
      const expired = subscriptionHasExpired(attMap[STRIPE_SUB_EXPIRY_KEY]);
      const inActive = userSubscriptionStatusINACTIVE(attMap[SLUG_SUBSCR_STATUS_KEY]);
      const trulyExpired = expired && inActive;
      return trulyExpired;
    }catch(error){
      return false;
    }
  }

  export function userSubscriptionInactiveAndExpiredMessage (cognitoUser){
    let msg ="";
    if (userSubscriptionInactiveAndExpired(cognitoUser)){
      const attMap = cognitoUser.attributes;
      const exp = 1000*parseInt(attMap[STRIPE_SUB_EXPIRY_KEY]);
      msg = ", it expired on " + new Date(exp).toDateString();
    }
    return msg;
  }
  

  /**
   * 
   * The functions below use the cognitoUser object returned from Authenticator
   * The attributes are accessible directly and are not in the UserAttributes Array
   *  
   */
  export function CognitoUserSubscriptionNotActive(cognitoUser){
    try{
      const expiryDate = cognitoUser.attributes[STRIPE_SUB_EXPIRY_KEY];
      const f = userSubscriptionExpiryDateInFuture(expiryDate);
      const someActiveStatus = userSubscriptionStatusINACTIVE(cognitoUser.attributes[SLUG_SUBSCR_STATUS_KEY])|| userSubscriptionStatusACTIVE(cognitoUser.attributes[SLUG_SUBSCR_STATUS_KEY]);
      const paused = (!f) || !someActiveStatus;
      console.log("CU:",cognitoUser," Not Active:", paused);
      return paused;
    }catch(error){
      return false;
    }
  }
  
  export function CognitoUserSubscriptionActive(cognitoUser){
    try{
      const expiryDate = cognitoUser.attributes[STRIPE_SUB_EXPIRY_KEY];
      const f = userSubscriptionExpiryDateInFuture(expiryDate);
      
      const active = userSubscriptionStatusACTIVE(cognitoUser.attributes[SLUG_SUBSCR_STATUS_KEY]);
      const b = f && active;
      console.log("CU:",cognitoUser," Active:", b);
      return b;
    }catch(error){
      return false;
    }
  }

  export function CognitoUserSubscriptionPaused(cognitoUser){
    try{
      const expiryDate = cognitoUser.attributes[STRIPE_SUB_EXPIRY_KEY];
      const f = userSubscriptionExpiryDateInFuture(expiryDate);
      const inActive = userSubscriptionStatusINACTIVE(cognitoUser.attributes[SLUG_SUBSCR_STATUS_KEY]);
      const paused = f && inActive;
      console.log("CU:",cognitoUser," Paused:", paused);
      return paused;
    }catch(error){
      return false;
    }
  }