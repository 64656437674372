import * as React from "react";

import { useContext } from "react";

import { API } from "aws-amplify";
import { DynamicSlugPersonQueryTemplate } from "../graphql/slugqueries";
import { AndEnumFilterGenerator } from "../graphql/util/filterbuilder";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import SelectedCharacteristics from "./SelectedCharcteristics";

import SignInManager from "../components/SignInManager.js";

import CharacteristicsSelector from "./CharacteristicsSelector";
import SlugSearchResults from "./SlugSearchResults";
import { ApplicationContext } from "../contexts/applicationcontext";
import { FilteredPeopleContext } from "../contexts/filteredpeoplecontext";
//import { AuthDetailsContextProvider } from "../contexts/authdetailscontext.js";
import ConfirmationDialog from "./ConfirmationDialogue.js";
import DeleteIcon from "@mui/icons-material/Delete";
const drawerWidth = "auto";
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);
let peopleFromAPI = new Array(0);
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SlugDrawer() {
  console.log (`p.E ${process.env.SLUGHORN_APP_TITLE}`);
  const { enumMap, setEnumMap } = useContext(ApplicationContext); // Get the current value of the map of enums
  var myarr = Array.from(enumMap);
  const { filteredPeople, setFilteredPeople } = useContext(FilteredPeopleContext);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  React.useEffect(() => {
    searchPeople(enumMap);
  }, []);

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  // This gets called whenever we close the drawer, good.
  // We could execute the searchPeople here
  const handleDrawerClose = () => {
    console.log("IN DRAWER", enumMap);
    window.localStorage.removeItem("ENUMMAP_KEY");
    window.localStorage.setItem(
      "ENUMMAP_KEY",
      JSON.stringify(Array.from(enumMap))
    );
    console.log("Save map to localStorage", enumMap);
    searchPeople(enumMap);
    setDrawerOpen(false);
  };

  // Just close the drawer without updating search for people
  const handleDrawerQuit = () => {
    /**The below works but does not reset the radio buttons to the original map - visually confusing
     * You cant uncomment this until you know how to zap the current radiobutton selections inside the component enumselector
     * setEnumMap(originalEnumMap);
     *  */

    setDrawerOpen(false);
  };
  // Just clear the selection map updating search for people
  /**The below works but does not reset the radio buttons to the original map - visually confusing
   * You cant uncomment this until you know how to zap the current radiobutton selections inside the component enumselector
   * setEnumMap(originalEnumMap);
   *  */

  const handleDrawerClear = () => {
    //console.log ("Clearing All selections");
    const clearMap = new Map();
    //enumMap.clear();
    setEnumMap(clearMap);
    window.localStorage.removeItem("ENUMMAP_KEY");
    myarr = Array.from(enumMap);
  };

  async function searchPeople(map) {
    if (map === undefined) {
      return;
    }
    const filter = AndEnumFilterGenerator(map);
    //console.log("Filter is ", filter);
    const queryTemplate = DynamicSlugPersonQueryTemplate;
    var queryToExecute = "";
    if (map.size > 0) {
      queryToExecute = queryTemplate.replace("FILTER", filter);
      try {
        const apiData = await API.graphql({
          query: queryToExecute,
        });
        console.log("APIDATA", apiData);
        peopleFromAPI = await apiData.data.listSlugPeople.items;
        /*
        Uncomment this block when you manage to clear the selections in the characteristics selector
        if (peopleFromAPI.length > 50){
          setConfirmOpen(true);
        }else{
          setFilteredPeople(peopleFromAPI);
        }
        */
        setFilteredPeople(peopleFromAPI);// And take this out when the above is uncommented
       
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      setFilteredPeople(new Array(0));
    }
  }

  function pullEntireSet() {
    console.log(`PULLING STUFF`);
    setFilteredPeople(peopleFromAPI);
    
  }

  function clearResults(){
    setFilteredPeople(new Array(0));
    setEnumMap(new Map());
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
          >
            <SearchIcon />
          </IconButton>
          <SelectedCharacteristics myarr={myarr} />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, width: "200px" }}
          >
            SlugHorn AI
          </Typography>

          <SignInManager />
          <div>   
            <ConfirmationDialog
              title="Please Confirm"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={pullEntireSet}
              onReject ={clearResults}
            >
              <Typography>
                    You will fetch {peopleFromAPI.length} results, is this OK?
                  </Typography>
            </ConfirmationDialog>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <DrawerHeader>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <IconButton disabled={true} onClick={handleDrawerClear}>
                <PhonelinkEraseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <IconButton onClick={handleDrawerQuit}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleDrawerClose}>
            <FaceRetouchingNaturalIcon
              fontSize="large"
              sx={{ color: "#1a76d2" }}
            />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <CharacteristicsSelector />
      </Drawer>
      <Main open={drawerOpen}>
        <DrawerHeader />
        <SlugSearchResults />
      </Main>
    </Box>
  );
}
