import * as React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import BackupIcon from "@mui/icons-material/Backup";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import { useContext } from "react";

import EnumRadioSelector from "../components/EnumRadioSelector";
import { PersonEditorContext } from "../contexts/personeditorcontext";
import characteristicsList from "../components/static/characteristics.json";
import { UpdateSlugPerson } from "../administration/SlugPersonManagement";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { userIsModerator } from "../components/util/CognitoUserUtils.js";
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import Box from '@mui/material/Box';
import { pink } from "@mui/material/colors";
import ClearIcon from '@mui/icons-material/Clear';

const theme = createTheme({
  typography: {
    fontSize: 10,
  },
});
function SimpleDialog(props) {
  const { onClose, map, open, person, cognitoUser } = props;
  var p = person.person; // why is this wrapped?
  const handleClose = () => {
    onClose();
  };
  // Here we handle the api call to update the person
  const handleUpdate = () => {
    const updatedPerson = updatePersonAttributes(map, p);
    const response = UpdateSlugPerson(cognitoUser, updatedPerson);
    console.log(`PERSON STORED  ${JSON.stringify(response)}`);
    onClose();
  };

  const handleQuit = () => {
    onClose();
  };

  // Take the map of attributes and update the person accordingly
  function updatePersonAttributes(map, person) {
    for (let [key, value] of map) {
      console.log(key + " is " + value);
      switch (key) {
        case "Gender":
          person.gender = value;
          break;
        case "Party":
          person.party = value;
          break;
        case "PersonType":
          person.persontype = value;
          break;
        case "Glasses":
          person.glasses = value;
          break;
        case "SkinTone":
          person.skintone = value;
          break;
        case "facialHair":
          person.facialhair = value;
          break;
        case "HairColor":
          person.haircolor = value;
          break;
        case "HairStyle":
          person.hairstyle = value;
          break;
        case "AgeGroup":
          person.agegroup = value;
          break;
        default:
      }
    }
    return person;
  }
  const fullname = `${p.first_name} ${p.last_name}`;
  return (
    <Dialog xs={{ align: "center" }} onClose={handleClose} open={open}>
      <CardContent id="cardcontent">
        <table align="center">
          <tbody>
            <tr>
              <td>
                <CardMedia
                  id="cardmedia"
                  component="img"
                  height="150"
                  image={p.image}
                  alt={p.external_id}
                />
              </td>
            </tr>
            <tr>
              <td>
                <ThemeProvider theme={theme}>
                  <Typography>
                    {p.first_name} {p.last_name}
                  </Typography>
                </ThemeProvider>
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      {characteristicsList.map((characteristic) => (
        <EnumRadioSelector
          key={characteristic}
          enumType={characteristic}
          enumMap={map}
          selectorSize={40}
          
        />
      ))}
      <Box>
      <IconButton sx={{float:"right"}} onClick={handleUpdate}>
        <BackupIcon sx={{color:"#8bc34a"}}/>
      </IconButton>
      <IconButton sx={{float:"right"}} onClick={handleQuit}>
        <ClearIcon sx={{color:pink}}/>
      </IconButton>
      </Box>
    </Dialog>
  );
}

export default function CharacteristicsEditor(person) {
  const [open, setOpen] = React.useState(false);
  const { cognitoUser } = useContext(CognitoUserContext);
  const { enumMap, setEnumMap } = useContext(PersonEditorContext); // Get the current value of the map of enums
  const m = personToAttributeMap(person);
  const isModerator = userIsModerator(cognitoUser);
  if (!isModerator){
    return (<div/>);
  }
  //setEnumMap(m);
  var myarr = Array.from(m);
  
  //const [selectedValue, setSelectedValue] =
  // const [selectedValue, setSelectedValue] = React.useState(map);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    //setSelectedValue(value)
    for (let [key, value] of m) {
      console.log(key + " is " + value);
    }
  };
  if (!isModerator) {
    return <div />;
  } else {
    return (
      <div>
        <IconButton
          variant="outlined"
          sx={{
            float:"right",
            borderRadius: 10,
            display: "flex",
            gap: 0,
            height: 20,
            fontSize: "0.7rem",
            fontWeight: "bold",
            letterSpacing: ".05rem",
            textTransform: "uppercase",
            color:"#1a76d2"
          }}
          onClick={handleClickOpen}
        >
          <FaceRetouchingNaturalIcon/>
        </IconButton>
        <SimpleDialog
          person={person}
          map={m}
          open={open}
          onClose={handleClose}
          cognitoUser={cognitoUser}
        />
      </div>
    );
  }
}

function personToAttributeMap(person) {
  const p = person.person;
  let map = new Map();
  map.set("Gender", p.gender);
  map.set("Party", p.party);
  map.set("PersonType", p.persontype);
  map.set("Glasses", p.glasses);
  map.set("SkinTone", p.skintone);
  map.set("FacialHair", p.facialhair);
  map.set("HairColor", p.haircolor);
  map.set("HairStyle", p.hairstyle);
  map.set("AgeGroup", p.agegroup);
  return map;
}
