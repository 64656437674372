import React from "react";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


/**
 * This is an unused and untested dialogue template
 */

const ConfirmationDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, onReject } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <IconButton
          variant="contained"
          onClick={() => {
            setOpen(false);
            onReject();
          }}
          color="primary"
        >
         <ThumbDownIcon/>
        </IconButton>
        <IconButton
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="default"
        >
          <ThumbUpIcon/>
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

