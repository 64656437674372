import * as React from "react";


import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

import Box from "@mui/material/Box";
export default function SimpleBottomNavigation() {
  

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0}}
      elevation={3}
    >
       <Box align="center">
       <Typography sx={{ fontSize: 10 }}>© Copyright Liberty Communications Limited 2023</Typography>
        </Box>
    </Paper>
  );
}
