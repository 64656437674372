import React, { createContext, useState } from "react";

export const PersonEditorContext = createContext();

export const PersonEditorProvider = (props) => {
  
  //const data = window.localStorage.getItem("ENUMMAP_KEY");
  //const arr = JSON.parse(data);
  const characteristicsMap = new Map();
  
  

  const [enumMap, setEnumMap] = useState(characteristicsMap);

  return (
    <PersonEditorContext.Provider
      value={{
        enumMap,
        setEnumMap,
      }}
    >
      {props.children}
    </PersonEditorContext.Provider>
  );
};
